/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg,
image {
  display: block;
  max-width: 100%;
}

/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

body {
  font-family: "DM Mono", monospace;
  background-size: 1200px;
  font-size: .8rem;
  text-wrap: pretty;
  background-color: #000;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  color: #ddd;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 1000px) {
  body {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  body {
    font-size: 1rem;
  }
}

section {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  padding: 2em;
}

@media screen and (min-width: 1000px) {
  section {
    padding: 2em;
  }
}

section:nth-child(3n+1) {
  background: #111 url('./images/texture.png') center right;
}

section:nth-child(3n+2) {
  background-color: #222;
}

section:nth-child(3n+3) {
  color: black;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}

section:nth-child(3n+3):hover {
  background-color: yellow;
}

.suitor {
  color: #fff;
  background: #f9313e url('./images/texture.png') !important;
}

.music.bandcamp a {
  color: black;
}

.music:hover {
  background-color: yellow;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}

.music a:hover {
  color: black;
}

h1,
h2,
.h2 {
  font-family: "Helvetica", "Arial", sans-serif;
}

h1 {
  font-size: 2.25rem;
  line-height: 100%;
  letter-spacing: 0.2em;
  margin: 2rem 0;
  text-transform: lowercase;
}

h1 span {
  position: relative;
  top: -3px;
}

h1 span.n1 {
  top: 0;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
    letter-spacing: 0.3em;
  }

  h1 span {
    top: -7px;
  }
}

@media screen and (min-width: 1400px) {
  h1 {
    font-size: 4rem;
  }
}

h1 span:last-of-type,
h2 span:last-of-type,
.h2 span:last-of-type {
  letter-spacing: 0;
}

h2,
.h2 {
  text-transform: lowercase;
  font-weight: bold;
  line-height: 100%;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  margin-bottom: 1rem;
}

h2 span,
.h2 span {
  position: relative;
}

h2 span.n2,
.h2 span.n2 {
  top: -2px;
}


h2 span.n3,
.h2 span.n3 {
  top: -4px;
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  color: yellow;
  font-weight: 500;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
  color: white;
}

.portrait {
  padding: 0;
}

.portrait img {
  position: relative;
}

.portrait-first img {
  left: 50%;
}

.portrait-last img {
  right: 50%;
}

section.music {
  padding: 0;
}

.music a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.music svg {
  width: 4rem;
  height: 4rem;
}

@media screen and (max-width: 1000px) {
  .music.single a {
    flex-direction: row;
    margin-top: 0.5rem;
  }
  
  .music svg {
    
    width: 2rem;
    height: 2rem;
  }

  .merch {
    padding: 0rem;
  }
}

.merch {
  display: relative;
}

.merch div {
  visibility: hidden;
  position: absolute;
  display: inline-block;
  color: #fff;
}

.merch span {
  background-color: #000;
  line-height: 1;
  padding: 1px 6px;
}

.merch:hover,
.merch:focus {
  background-color: #ccc;

  img {
    filter: invert(1) saturate(0);
  }

  div {
    visibility: visible;
  }
}

.merch a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.press-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.subtle {
  opacity: 0.5;
}

.members {
  text-align: left;
}

dt,
dd {
  display: inline-block;
}

dt {
  width: 5rem;
  text-align: right;
  text-transform: uppercase;
}

dt:after {
  content: "/";
  opacity: 0.5;
  margin-right: 0.5rem;
}