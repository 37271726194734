*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg, image {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

body {
  text-wrap: pretty;
  color: #ddd;
  text-align: center;
  text-shadow: 0 1px #00000040;
  background-color: #000;
  background-size: 1200px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-family: DM Mono, monospace;
  font-size: .8rem;
  display: grid;
}

@media screen and (width >= 1000px) {
  body {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (width >= 1200px) {
  body {
    font-size: 1rem;
  }
}

section {
  aspect-ratio: 1;
  background-color: #ddd;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  display: flex;
  overflow: hidden;
}

@media screen and (width >= 1000px) {
  section {
    padding: 2em;
  }
}

section:nth-child(3n+1) {
  background: #111 url("texture.a1a65412.png") 100%;
}

section:nth-child(3n+2) {
  background-color: #222;
}

section:nth-child(3n+3) {
  color: #000;
  text-shadow: 0 1px #ffffff40;
}

section:nth-child(3n+3):hover {
  background-color: #ff0;
}

.suitor {
  color: #fff;
  background: #f9313e url("texture.a1a65412.png") !important;
}

.music.bandcamp a {
  color: #000;
}

.music:hover {
  text-shadow: 0 1px #ffffff40;
  background-color: #ff0;
}

.music a:hover {
  color: #000;
}

h1, h2, .h2 {
  font-family: Helvetica, Arial, sans-serif;
}

h1 {
  letter-spacing: .2em;
  text-transform: lowercase;
  margin: 2rem 0;
  font-size: 2.25rem;
  line-height: 100%;
}

h1 span {
  position: relative;
  top: -3px;
}

h1 span.n1 {
  top: 0;
}

@media screen and (width >= 800px) {
  h1 {
    letter-spacing: .3em;
    font-size: 3rem;
  }

  h1 span {
    top: -7px;
  }
}

@media screen and (width >= 1400px) {
  h1 {
    font-size: 4rem;
  }
}

h1 span:last-of-type, h2 span:last-of-type, .h2 span:last-of-type {
  letter-spacing: 0;
}

h2, .h2 {
  text-transform: lowercase;
  letter-spacing: .1em;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 100%;
}

h2 span, .h2 span {
  position: relative;
}

h2 span.n2, .h2 span.n2 {
  top: -2px;
}

h2 span.n3, .h2 span.n3 {
  top: -4px;
}

ul {
  padding-left: 0;
  list-style: none;
}

a {
  color: #ff0;
  font-weight: 500;
  text-decoration: none;
}

a:hover, a:focus {
  color: #fff;
  text-decoration: underline;
}

.portrait {
  padding: 0;
}

.portrait img {
  position: relative;
}

.portrait-first img {
  left: 50%;
}

.portrait-last img {
  right: 50%;
}

section.music {
  padding: 0;
}

.music a {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.music svg {
  width: 4rem;
  height: 4rem;
}

@media screen and (width <= 1000px) {
  .music.single a {
    flex-direction: row;
    margin-top: .5rem;
  }

  .music svg {
    width: 2rem;
    height: 2rem;
  }

  .merch {
    padding: 0;
  }
}

.merch {
  display: relative;
}

.merch div {
  visibility: hidden;
  color: #fff;
  display: inline-block;
  position: absolute;
}

.merch span {
  background-color: #000;
  padding: 1px 6px;
  line-height: 1;
}

.merch:hover, .merch:focus {
  background-color: #ccc;
}

:is(.merch:hover, .merch:focus) img {
  filter: invert() saturate(0);
}

:is(.merch:hover, .merch:focus) div {
  visibility: visible;
}

.merch a {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.press-links {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.subtle {
  opacity: .5;
}

.members {
  text-align: left;
}

dt, dd {
  display: inline-block;
}

dt {
  text-align: right;
  text-transform: uppercase;
  width: 5rem;
}

dt:after {
  content: "/";
  opacity: .5;
  margin-right: .5rem;
}
/*# sourceMappingURL=index.920d0789.css.map */
